@import "../../components/core.scss";

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@mixin commonPreviewLogo {
  margin-right: 7px;
  box-shadow: none !important;
  background-color: $lightCyanBlue;
  height: 34px !important;
  width: 34px !important;
}

@mixin addIconPixels($type) {
  @if $type ==web {
    height: 22px !important;
    width: 22px !important;
  } @else if $type ==phone {
    height: 17px !important;
    width: 17px !important;
  } @else if $type ==checkout {
    height: 34px !important;
    width: 34px !important;
  }
}

@mixin addLogoPixels($type) {
  @if $type ==web {
    height: 22px !important;
    width: auto !important;
    max-width: 125px;
  } @else if $type ==phone {
    height: 17px !important;
    width: auto !important;
    max-width: 100px;
  } @else if $type ==checkout {
    height: 34px !important;
    width: auto !important;
    max-width: 125px;
  }
}

@mixin cfDDOptionsMenuTruncate($width, $imp: true) {
  #custom-field-options {
    .custom-field-option-menu {
      .MuiBox-root {
        @if $imp {
          max-width: $width !important;
        } @else {
          max-width: $width;
        }
      }
    }
  }
}

@mixin commonLoader {
  .MuiPaper-root {
    width: 268px;
    height: 193px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @include boxShadow();

    .MuiCardMedia-root {
      width: 84px;
      height: 84px;
      margin: 0 25px 10px;
    }

    .MuiCardContent-root {
      padding: 0;
    }
  }
}

@mixin commonPayVia {
  display: flex;
  left: 35px;
}

@mixin commonOverlayBox {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 99;
  width: 100%;
}

@mixin disableMaskInput() {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
  background-color: $lightAliceBlue !important;
}

img:-moz-loading {
  visibility: hidden;
}

.connect-two-fa {
  margin-top: 55px !important;
}

.margin-left10 {
  margin-left: 10px;
}

.margin-top30 {
  margin-top: 30px;
}

.margin-top36 {
  margin-top: 36px;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom30 {
  margin-bottom: 30px;
}

.overFlowAuto {
  overflow: auto;
}

.margin-bottom15 {
  margin-bottom: 15px;
}

.margin-top15 {
  margin-top: 15px;
}

.margin-top14 {
  margin-top: 14px;
}

.margin-top49 {
  margin-top: 49px;
}

.margin-top58 {
  margin-top: 58px;
}

.expired-2fa-otp {
  color: $paleRed !important;
}

.content-center {
  @include contentCenter();
}

.mobile-view-branding {
  z-index: 0;
  width: 375px;
  position: relative;
  margin: 0 1px 0 0;
  border-radius: 10px;
  box-shadow: 3px 4px 14px 2px rgba(11, 37, 75, 0.12),
    0 0 0 1px rgba(11, 37, 75, 0.08);
  background-color: $white;

  &.mobile-view {
    border-radius: unset;
    box-shadow: none;
  }

  .mobile-view-header {
    padding: 9px 8px 25px 12px;
    height: auto;
    min-height: 174px;

    &.checkout-link {
      height: auto;
      min-height: 159px;
    }
  }

  .mobile-modal {
    .header-mobile-modal {
      display: flex;
      justify-content: space-between;
      height: 39px;
      border-bottom: solid 1px $lightCyanBlue;
      align-items: center;
      padding: 0 15px;

      .account-logo-mobile {
        border: solid 1px $greyText;

        svg {
          font-size: $font10;
        }
      }
    }

    .mobile-modal-list {
      padding: 0 15px;
      overflow: auto;
      height: 90%;
    }
  }

  .products-btn {
    width: 65px;
    height: 23px;
    border-radius: 15px;
    background-color: $white;
    margin-top: 20px;
    cursor: pointer;
    @include contentCenter();
  }

  .preview-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .pay-via-divider {
    margin: 0px 23px;
    width: 287px !important;
  }

  .pay-via-txt {
    margin-left: 23px;
    width: fit-content;
    margin-bottom: 5px;
    margin-top: 7px;
  }

  @include scrollbar();

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }
}

.mobile-view-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-content: center;
  width: 100%;
}

.checkout-acc-pay {
  margin-top: 20px;
  font-size: $font14 !important;
  word-break: break-word;
}

.checkout-acc-name {
  max-width: 160px;
  margin-left: 7px;
  font-size: $font16 !important;
}

.account-name-text {
  margin-left: 7px;
  font-size: $font16;
}

.checkout-acc-amount {
  margin-top: 0;
  font-weight: 600;
}

.test-mode-badge {
  width: fit-content;
  padding: 2px 4px;
  height: 21px;
  background-color: $sunFlowerYellow;
  border-radius: 5px;
  margin-left: 6.67px;
}

.checkout-link-phone-preview {
  &.mobile-view-branding {
    margin: 15px 0;
    margin-bottom: 5px;
    border-radius: 0;
  }
}

.account-logo-style {
  border-radius: 4px !important;
  @include commonPreviewLogo();
  background-color: initial;
  height: 34px !important;
  width: auto !important;
  max-width: 125px;

  &.phone {
    @include addLogoPixels(phone);
  }

  &.web {
    @include addLogoPixels(web);
  }

  &.checkout {
    @include addLogoPixels(checkout);
  }
}

.account-icon-style {
  @include commonPreviewLogo();

  &.phone {
    @include addIconPixels(phone);
  }

  &.web {
    @include addIconPixels(web);
  }

  &.checkout {
    @include addIconPixels(checkout);
  }
}

.text-mode-badge {
  margin-left: 13px;
  width: 70px;
  height: 21px;
  border-radius: 3px;
  background-color: $sunFlowerYellow;
}

.account-logo-mobile {
  width: 17px;
  height: 17px;
  margin: 0 7px 0 0;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: $font12;
  }
}

.account-logo-default {
  margin-right: 7px;
  width: 33px;
  height: 33px;
  background-color: $white;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 21px;
    width: 21px;
  }
}

.confirm-payment-preview {
  height: 505px;
  border: solid 1px $lightCyanBlue;
  background-color: $white;
  padding: 0 25px;
  flex-direction: column;
  display: flex;
  align-items: center;

  .grand-payment-box {
    width: 100%;
    margin: 25px 0;
    padding: 0 12px;
    background-color: $paleGray;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  }

  .circle-icon {
    height: 44.2px;
    width: 44.2px;
  }
}

.latest-confirm-preview {
  height: unset;
  border: unset;
  padding: 0;

  .grand-payment-box {
    margin-top: 24px !important;
    padding: 0;

    .MuiBox-root {
      padding: 12px;
    }
  }
}

.web-preview-accordion {
  margin: 25px 69px 0 20px;

  &.web,
  &.branding,
  &.phone {
    .MuiAccordionDetails-root {
      height: 250px;
    }
  }

  &.mobile-accordion {
    margin: 15px;

    .MuiAccordionDetails-root {
      padding-bottom: 5px !important;

      .qr-code {
        margin: 0;
      }
    }
  }

  border: 1px solid $lightCyanBlue;
  border-radius: 5px;

  .MuiAccordion-root {
    .MuiAccordionSummary-root {
      cursor: initial;
      padding: 18px 16px 15px 18px !important;
    }

    border-bottom: solid 1px $lightCyanBlue;

    &:last-of-type {
      border-bottom: none;
    }

    .MuiAccordionDetails-root {
      // For future use in accordion
      // overflow: auto;
      // max-height: 275px;
      padding-bottom: 30px;
    }
  }

  .speed-carousel {
    &.create-link-page {
      .slick-arrow {
        height: 20px;
        width: 20px;
        top: calc(50% - 37px);
      }

      .slick-dots {
        margin-top: 0px;
      }
    }

    &.checkout-page {
      .slick-arrow {
        height: 30px;
        width: 30px;
      }
    }

    .slick-arrow {
      border-radius: 50%;
      border: solid 1px $darkIndigo;
      color: $darkIndigo;
      top: calc(50% - 43px);
      opacity: 0.7;
      z-index: 999;

      &:hover {
        opacity: 1;
      }

      &.slick-disabled {
        display: none !important;
      }

      &.slick-prev {
        left: 0;
      }

      &.slick-next {
        right: 0;
      }
    }

    .slick-dots {
      position: unset;
      margin-top: 10px;

      ::before {
        font-size: $font8;
      }

      li {
        height: 10px;
        width: 10px;
      }

      .slick-active {
        ::before {
          color: $primaryBlue;
        }
      }
    }
  }
}

.info-popper-box {
  display: flex;
  flex-direction: row;
  line-height: 1.5;

  &.MuiBox-root {
    box-shadow: none;
    padding: 5px 0px;
    color: $darkIndigo;

    &.sign-up {
      padding: 5px 10px;
      box-shadow: none;
      color: $darkIndigo;
    }
  }
}

.checkout-link-footer {
  width: 325px;
  @include contentCenter();
  justify-content: space-between;

  .checkout-page-text {
    &.secured-by-text {
      span {
        display: flex;
        align-items: center;
        line-height: normal;
      }
    }
  }
}

.crypto-text {
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
}

.crypto-address-text {
  @include contentCenter();
  // height: 23px;
  margin: 10px 27px 14px;
  padding: 10px 14px;
  border-radius: 21px;

  &.checkout-link-text {
    height: 14px;
    margin-top: 10px;
  }
}

.text-truncate {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.main-logo-login {
  @include contentCenter();
}

.logo-signup {
  display: flex;
  justify-content: center;
  padding: 20px 0 30px 0;
}

.vector-diagonal-div {
  background-color: $darkBlue;
  height: 800px;
  grid-row-start: 1;
  grid-column-start: 1;
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0 100%);
}

.vector-diagonal-sub-div {
  grid-row-start: 1;
  background-color: #9babfd;
  grid-column-start: 1;
  height: 350px;
  width: -webkit-fill-available;
  clip-path: polygon(0 85%, 100% 0, 100% 8%, 0 100%);
  opacity: 0.4;
  margin-top: 503px;
}

.loading-in-auth {
  @include commonLoader();

  .MuiPaper-root {
    width: 200px;
    height: 150px;
  }
}

.page-loader {
  &.MuiBackdrop-root {
    background-color: unset;
  }

  .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
  }
}

.expire-icon {
  margin: 12px 141px 15px 135px;
  object-fit: contain;
  width: 75px;
  height: 66px;
}

.expire-header {
  text-align: center;
  color: $darkIndigo;
  margin-bottom: 20px;
}

.expire-sub-header {
  width: 341px;
  text-align: center;
  color: #848b9e;
}

.expire-modal .MuiDialogActions-root {
  padding: 0px 30px 30px;
}

.expire-modal .MuiDialogContent-root {
  padding: 25px 30px;
}

.cashback-redeem-modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.18);
  }

  .MuiDialog-container {
    .MuiPaper-root {
      .success-cashback-redeem-gif {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      &.cashback-redeem-dialog {
        width: 340px;
      }

      .MuiDialogContent-root {
        .close-cashback-redeem-modal {
          position: absolute;
          right: 5px;
          top: 5px;
        }

        .cashback-redeem-icon {
          margin-top: 5px;
          display: flex;
          justify-content: center;

          img {
            width: 80px;
            height: 72px;
          }
        }

        .cashback-congratulation-text,
        .cashback-amount-text {
          margin-top: 25px;
          text-align-last: center;
        }

        .cashback-redeem-qr {
          margin-top: 25px;
        }

        .cashback-amount-text {
          .MuiTypography-root {
            font-family: $fontBold;
            color: $primaryBlue;
          }
        }

        .success-redeem-screen {
          padding: 138px 25px;

          .success-cashback-redeem-icon {
            display: flex;
            justify-content: center;

            img {
              height: 96px;
              width: 96px;
            }
          }

          .success-cashback-redeem-amount {
            font-family: $fontBold;
            color: $primaryBlue;
            margin-top: 40px;
          }

          .success-cashback-redeem-text {
            margin-top: 20px;
          }
        }
      }

      .MuiDialogActions-root {
        border-top: 1px solid $lightCyanBlue;
        justify-content: center;
        padding: 20px 15px;

        .cashback-redeem-modal-footer {
          display: flex;

          .cancel-cashback {
            margin-left: 20px;
            align-self: center;
            cursor: pointer;

            .MuiTypography-root {
              color: $primaryBlue;
            }
          }

          .email-section {
            .send-cashback-email {
              display: flex;

              .send-email-button {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.standard-capabilities-box {
  @include applyBorder($lightCyanBlue);
  margin-top: 17px;
  border-radius: 4px;

  .static-text {
    padding: 10px 16px;
    width: 50%;
    word-break: break-word;
    display: flex;
    align-items: center;
  }

  .content-table {
    &:nth-child(odd) {
      background-color: $paleGray;
    }
  }
}

.connect-list-selection {
  max-height: 225px !important;
}

.connect-partner-box {
  box-shadow: 0px 0px 0px 1px rgba(11, 37, 75, 0.08),
    0px 4px 4px -2px rgba(11, 37, 75, 0.12);
  border-radius: 5px;
  padding: 16px;
}

.account-list {
  @include addCustomScrollBar();
}

.margin-top25 {
  margin-top: 25px;
}

.create-account-country {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiAutocomplete-endAdornment {
        .MuiButtonBase-root {
          top: 0;
          right: 0;
          position: relative;
        }
      }
    }
  }
}

@media only screen and (max-width: 464px) {
  .expire-modal .MuiDialog-paper {
    width: 340px;
    margin: 0px;
  }

  .expire-sub-header {
    width: 280px;
  }

  .expire-icon {
    margin: 12px 102px 15px 116px;
    width: 50px;
    height: 44px;
  }
}

@media only screen and (max-width: 365px) {
  .expire-modal .MuiDialog-paper {
    width: 340px;
    margin: 0px;
  }

  .expire-icon {
    width: 50px;
    height: 44px;
    margin: 0 111px 15px;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.remove-circle-icon {
  color: $paleRed;
  width: 28px;
  height: 28px;
  cursor: pointer;

  &:focus {
    @include applyFocusBorder();
  }
}

.dropdown-option-modal {
  .MuiDialogContent-root {
    min-height: 179px;
    height: fit-content;
    position: relative;
  }
}

.app-root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-root-container {
    display: flex;
    height: 100%;

    // Sidebar
    // nav {
    //     height: 100%;
    // }
    .sidebar-closure-box {
      height: calc(100% - 110px) !important;
    }

    .sidebar-closure-box {
      height: calc(100% - 110px) !important;
    }

    .sidebar-wrapper {
      position: fixed;
      display: flex;
      flex-direction: column;
      height: calc(100% - 60px);

      .MuiDrawer-paper {
        width: 250px;
        position: relative;
        height: 100%;
      }

      .sidebar-content {
        padding: 15px;
      }

      .main-menu-item {
        padding: 0;

        .MuiList-root {
          padding: 0;

          .MuiListItem-root {
            padding-bottom: 10px;

            .MuiListItemButton-root {
              border-radius: 4px;
              padding: 9px;
              height: 42px;

              &.Mui-selected {
                background-color: $sunFlowerYellow;

                .MuiSvgIcon-root {
                  color: $darkIndigo;
                }
              }

              &:not(.Mui-selected):hover {
                background-color: $lightSunFlowerYellow;
              }

              .MuiListItemIcon-root {
                min-width: 36px;
              }

              .MuiListItemText-root {
                .MuiTypography-root {
                  font-family: $fontSemiBold;
                  line-height: 1.14;
                  font-size: $font14;
                  color: $darkIndigo;
                }
              }
            }

            &.main-menu-accordian-list {
              padding-bottom: 4px;
              display: block;
            }
          }
        }
      }

      .back-to-menu-btn {
        &.MuiTypography-root {
          color: $darkIndigo;
          padding: 9px 4px;
          margin-bottom: 8px;

          .MuiSvgIcon-root {
            color: $greyText;
            margin-right: 4px;
          }
        }
      }
    }

    .section-wrapper {
      .action-btn-wrapper {
        position: absolute;
        top: 99px; // need to change to 105px when tab is added
        right: 35px;

        .MuiButton-root {
          font-size: $font14;
        }
      }
    }
  }

  .signin-signup-container-layout {
    padding: 40px;
    width: 510px;
    height: auto;
    background: $white;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);

    .MuiCollapse-horizontal {
      height: auto !important;
    }
  }

  .container-layout {
    display: flex;
    justify-content: center;
  }
}

.align-left-right-content {
  display: flex;
  justify-content: space-between;
}

// Header
.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 11;

  &.MuiBox-root {
    border-bottom: solid 2px #eceef1;
  }

  .MuiPaper-root {
    &.MuiAppBar-root {
      background-color: $white;
      justify-content: center;
      height: 82px;
      box-shadow: none;
    }
  }

  .header-logo {
    cursor: pointer;
    width: 129px;
    height: 36px;
  }

  &.user-in {
    .MuiContainer-root {
      margin: 0;
      max-width: 100%;
      padding: 0;

      > .MuiPaper-root {
        height: 62px;

        .MuiToolbar-root {
          padding: 19px;

          .MuiIconButton-root {
            color: $greyText;
            padding-right: 15px;
          }
        }

        .support-center-box-wrapper {
          display: flex;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;

          .MuiSvgIcon-root {
            color: $greyText;
          }
        }

        .header-user-profile {
          &:focus-visible {
            @include applyFocusBorder();
          }
        }
      }
    }
  }

  .oauth-header {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .app-icon-padding {
    padding-left: 244px;
  }
}

#support-center-drawer {
  .MuiBackdrop-root {
    background-color: unset;
  }

  &.support-center-drawer {
    .MuiDrawer-paper {
      box-shadow: -10px 0 44px -2px rgba(11, 37, 75, 0.12),
        0 0 0 1px rgba(11, 37, 75, 0.08);
      overflow: hidden;
      border: none;

      .drawer-wrapper {
        width: 480px;
        height: 100%;
        min-width: 450px;

        .drawer-header {
          display: flex;
          padding: 20px 25px;
          background-color: $paleGray;
          justify-content: space-between;
          align-items: center;
        }

        .support-center-body-content {
          padding: 30px 25px 30px 30px;
          height: calc(100% - 67px);
          overflow: auto;

          .MuiSvgIcon-root {
            color: $greyText;
          }

          .support-center-accordian {
            border-radius: 5px;
            margin-bottom: 25px;
            @include applyBorder($lightCyanBlue);

            .MuiAccordionDetails-root {
              .MuiList-root {
                padding-top: 0px;

                .support-team-box,
                .success-manager-box {
                  padding: 20px;
                }

                .support-team-box {
                  .support-details {
                    display: flex;
                    align-items: center;
                    margin: 5px 0px 25px;

                    &:last-child {
                      margin: 0px 0px 5px;
                    }

                    .support-chat-btn {
                      @include applyBorder($darkIndigo);
                      height: 36px;
                      margin-left: auto;
                      letter-spacing: normal;

                      .MuiSvgIcon-root {
                        font-size: $font18;
                        color: $darkIndigo;
                      }

                      div {
                        color: $darkIndigo;
                        font-size: $font13;
                        top: -1px;
                        position: relative;
                      }
                    }
                  }
                }

                .success-manager-box {
                  .name-text {
                    line-height: 1.63;
                    margin-bottom: 15px;
                  }

                  .bio-text {
                    line-height: 1.86;
                    margin-bottom: 20px;
                  }

                  .success-manager-details {
                    display: flex;
                    align-items: center;
                    margin: 20px 0px;

                    &:last-child {
                      margin-bottom: 0px;
                    }

                    .schedule-meeting-btn {
                      border: 1px solid #0a193e;
                      height: 36px;
                      margin-left: auto;

                      .MuiSvgIcon-root,
                      div {
                        color: $darkIndigo;
                      }

                      div {
                        font-size: $font13;
                        letter-spacing: normal;
                      }
                    }
                  }
                }
              }
            }

            .MuiAccordionSummary-root {
              padding: 14px 20px;
              color: $darkIndigo;

              .MuiAccordionSummary-content {
                .MuiTypography-root {
                  font-size: $font16;
                }
              }
            }
          }

          .share-suggestion-box {
            &:hover {
              background-color: $backgroundHover;
            }

            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding: 14px 20px;
            border-radius: 5px;
            @include applyBorder($lightCyanBlue);
            text-decoration: none;
            color: inherit;

            .suggestion-text-box {
              .share-suggestion-text {
                line-height: 1.63;
              }

              .share-suggestion-subtext {
                line-height: 1.86;
              }
            }

            .forward-arrow-icon {
              display: flex;
              align-items: center;

              .MuiSvgIcon-root {
                font-size: $font14;
              }
            }
          }
        }
      }
    }

    @include scrollbar();
  }
}

#header-profile-menu {
  &.MuiModal-root {
    &.MuiPopover-root {
      .MuiPopover-paper {
        width: 133px;
        margin-left: 0;
        box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
        @include applyBorder($lightCyanBlue);

        .MuiList-root {
          padding: 11px 10px !important;

          .MuiMenuItem-root {
            height: 36px;
            font-size: $font12;
            font-family: $fontMedium;

            &:focus-visible {
              background-color: $backgroundHover;
            }
          }

          .MuiSvgIcon-root {
            margin-right: 8px;
            color: $primaryBlue;
            font-size: $font20;
          }
        }
      }
    }
  }
}

.overlay-container {
  @include commonOverlayBox();

  .MuiContainer-root {
    padding: 0 !important;
  }
}

.backdrop-loader {
  color: $white;
  z-index: 1201;
  background-color: #b0b3b666;

  &.preview-loader {
    z-index: 0;
  }
}

.label-text {
  padding-bottom: 8px;
  color: #848b9e;
}

.custom-payout-tag-icon {
  flex-direction: row-reverse;

  & .MuiChip-icon {
    height: 11px;
    width: 11px;
    color: $white !important;
    margin-right: 0;
  }
}

.custom-payouts-status-label {
  & .MuiChip-label {
    line-height: normal !important;
  }
}

.payouts-status-tooltip {
  .MuiTooltip-tooltip {
    font-size: $font14 !important;
  }
}

.app-root-container {
  width: 100%;
}

.wallet-root-container {
  height: calc(100% - 64px) !important;
}

.common-grid-wrapper {
  margin: 54px 10px 58px;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;

  .email-password-grid {
    flex-direction: column;
    align-items: center;
  }
}

// Common checkout actions CSS

@mixin commonCheckoutPageBoxCss() {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
}

@mixin commonMobileViewBranding() {
  // flex: 1 1 0%;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

@media only screen and(height:720px) {
  .checkout-page-box {
    min-height: 100vh;

    .mobile-view-branding {
      flex: 1 1 0%;
    }
  }
}

@mixin commonMobileViewBrandingWithCheckoutWrapper() {
  &.mobile-view-branding {
    // margin: 51px 0 19px 0;
    // width: 468px;
    width: 375px;
    border-radius: 0;

    .mobile-view-header {
      padding: 21px 20px 31px 20px;
      height: auto;
      min-height: 160px;

      .account-logo-mobile {
        width: 34px;
        height: 34px;
        border-radius: 20px;

        svg {
          font-size: $font20;
        }
      }
    }
  }
}

.heading {
  color: $white;
  margin-top: 40px;
  font-size: $font45 !important;

  &.web {
    margin-top: 102px;
  }
}

.action-box {
  height: auto;
}

.action-body {
  flex-direction: column;
  @include contentCenter();
}

.action-main-text {
  color: $darkIndigo;
  margin-top: 30px;
  margin-bottom: 8px;
  font-size: $font18 !important;
}

.action-sub-text {
  color: $greyText;
  font-size: $font14 !important;
}

/** End **/

.payment-page-preview {
  min-height: 690px !important;
  padding: 0px !important;
  position: relative;
}

.payment-page-preview-contact-info-scroll {
  max-height: 690px;
  overflow-y: auto;
}

.live-test-mode-header {
  height: 21px;
  @include contentCenter();
  background-color: $sunFlowerYellow;
}

// New Preset Amount as per latest design -- START--
.label-type {
  position: relative;
  display: block;
  padding-right: 110px;
  width: 390px;
  height: 60px;
  box-sizing: border-box;
}

.label-type::after {
  content: "| " attr(data-currency);
  position: absolute;
  top: 25px;
  left: 299px;
  font-size: $font24;
  font-style: normal;
  font-weight: 400;
  display: block;
  font-family: $fontRegular;
  color: #667085;
}

.mask-label-disabled::after {
  @include disableMaskInput();
}

.preset-amount-preview {
  @include contentCenter();
  justify-content: unset !important;

  .mask-input-wrapper {
    .mask-input {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 2px;
      width: 150px;
      height: 36px;
      margin: 6px 0;
      color: $white;

      &:focus-visible {
        outline: none;
      }

      &.input-adornment {
        position: relative;
        display: block;
        padding-right: 110px;
        width: 390px;
        height: 60px;
        box-sizing: border-box;
        background-color: $white;
        color: $darkIndigo;
        font-size: $font36;
        border-radius: 4px;
        font-weight: 600;
      }
    }
  }
}

// Preset Amount type Preview
.preset-amount-view {
  display: flex;

  .mask-input-wrapper {
    .mask-input {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 2px;
      width: 150px;
      height: 36px;
      margin: 6px 0;
      color: $white;

      &:focus-visible {
        outline: none;
      }

      &.checkout {
        height: 16px !important;
      }
    }
  }
}

.payment-page-qr-section {
  box-sizing: unset;
  height: auto;

  .transition-on {
    animation: qr-section-height-transition 0.56s linear;

    @keyframes qr-section-height-transition {
      0% {
        height: 0px;
      }

      100% {
        height: 495px;
      }
    }
  }

  .payment-page-qr-footer {
    margin: 15px 27px;
    display: flex;
    justify-content: space-between;

    .qr-footer-box {
      flex: 1;
      // width: 40%;
      border-radius: 4px;
      border: solid 1px $lightCyanBlue;
      padding: 9px 10.5px 9px 10px;
      // padding: 9px 17px;

      // height: 22px;
      text-decoration: none;
      @include contentCenter();

      &:focus-visible {
        outline: none;
      }
    }
  }
}

@mixin commonDiagonalCSS($height, $polygon) {
  height: $height;
  clip-path: $polygon;
}

.diagonal-div {
  grid-row-start: 1;
  grid-column-start: 1;
  @include commonDiagonalCSS(379px, polygon(0 0, 100% 0, 100% 100%, 0 60%));

  &.web {
    @include commonDiagonalCSS(560px, polygon(0 0, 100% 0, 100% 100%, 0 87%));
  }

  &.checkout {
    @include commonDiagonalCSS(660px, polygon(0 0, 100% 0, 100% 100%, 0 87%));
  }

  &::before {
    opacity: 0.4;
    content: "";
    position: absolute;
  }
}

.main-white-logo {
  height: 48px;
  width: 163.276px;
}

.diagonal-sub-div {
  grid-row-start: 1;
  grid-column-start: 1;
  width: -webkit-fill-available;
  opacity: 0.4;
  margin-top: 227px;
  @include commonDiagonalCSS(198px, polygon(0 0, 100% 77%, 100% 100%, 0 11%));

  &.web {
    @include commonDiagonalCSS(380px, polygon(0 0, 100% 75%, 100% 100%, 0 75%));
  }

  &.checkout {
    @include commonDiagonalCSS(480px, polygon(0 0, 100% 75%, 100% 100%, 0 77%));
  }
}

@mixin imageUploadBoxIcon($dimension) {
  height: $dimension;
  width: $dimension;
  opacity: 0.4;
  color: $greyText;
}

.image-upload-box {
  border-radius: 4px;
  height: 140px;
  width: 140px;
  margin: 16px 0;
  margin-top: 70px;
  border: solid 1px $lightCyanBlue;
  @include contentCenter();
  cursor: pointer;

  &.link-form {
    margin: 0;
  }

  .add-icon {
    @include imageUploadBoxIcon(46px);
  }

  .image-icon {
    @include imageUploadBoxIcon(32px);
  }
}

.page-description-preview-box {
  height: auto !important;
  max-height: 117px;
  overflow: auto;
  overflow-x: hidden;
}

.pl-preview-image {
  border-radius: 4px;
  height: 140px;
  width: auto;
  min-width: 140px;
  max-width: 395px;
  margin-top: 70px;
  border: 1px solid $greyText;

  &.link-detail,
  &.link-form {
    margin: 0;
    max-width: 350px;
  }
}

.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.instant-send-note {
    -webkit-line-clamp: 3;
  }
}

.page-title {
  font-size: $font16 !important;
}

.page-description {
  font-size: $font14 !important;
}

.timer-content {
  align-self: center;
  width: 42px;

  .MuiTypography-root {
    font-size: $font12;
  }
}

.blink-me {
  animation: blinker 1s linear infinite;
}

.text-capitalize {
  text-transform: capitalize;
}

.image_upload-box {
  border: 1px dashed $lightCyanBlue;
  border-radius: 4px;
  padding: 33px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 520px;
  margin-top: "20px";
  cursor: pointer;

  &.link-form {
    margin: 0px;
  }

  &.instant-send-upload-qr {
    width: 100%;
    background: $paleGray;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  .checkout-page-box,
  .withdraw-page-box {
    justify-content: unset !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) {
  .checkout-page-box {
    justify-content: center !important;
  }
}

@media only screen and (min-device-width: 601px) and (max-device-width: 1023px) {
  .checkout-page-box {
    justify-content: center !important;
  }
}

@media only screen and (min-device-width: 1024px) {
  .email-password-grid {
    &.sign-in-grid {
      width: 432px;
    }
  }
}

@media only screen and (width: 1024px) {
  @include cfDDOptionsMenuTruncate(360px);
}

@media only screen and (min-width: 1025px) {
  @include cfDDOptionsMenuTruncate(390px);
}

@media only screen and (min-width: 600px) and (max-width: 935px) {
  @include cfDDOptionsMenuTruncate(295px, true);
}

@media only screen and (width: 360px) {
  @include cfDDOptionsMenuTruncate(295px, true);
}

@media only screen and (min-device-width: 280px) and (max-device-width: 420px) {
  .pay-via-txt-payment,
  .pay-via-divider {
    width: 345px !important;
  }

  .mobile-modal {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 421px) and (max-device-width: 600px) {
  .pay-via-txt-payment,
  .pay-via-divider {
    width: 355px !important;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 480px) {
  .main-white-logo {
    height: 42px !important;
  }

  .header {
    height: 60px;

    .header-logo {
      width: 85px;
      height: 25px;
    }

    .MuiPaper-root {
      &.MuiAppBar-root {
        height: 59px;
      }

      .MuiToolbar-root {
        padding: 0 20px;
      }
    }
  }

  .section-container {
    &.MuiBox-root {
      overflow-y: scroll;
    }
  }

  .common-grid-wrapper {
    margin-top: 50px;

    .email-password-grid {
      &.sign-in-grid {
        width: 285px;
      }
    }
  }

  .checkout-page-box {
    .heading {
      font-size: 44px !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .email-password-grid {
    &.sign-in-grid {
      width: 430px;
    }
  }
}

@mixin adminAuthImageMobile($type) {
  @if ($type ==mobile) or ($type ==ipad) {
    .bottom-image {
      display: none;
    }

    .right-image {
      width: 43px;
      height: 85px;
      top: 105px;
    }

    .left-image {
      width: 60px;

      @if $type ==mobile {
        top: 530px;
      } @else {
        top: 570px;
      }
    }
  } @else {
    .bottom-image {
      position: fixed;
      bottom: 0;
      right: 5%;
    }

    .right-image {
      position: absolute;
      right: 0;
      top: 210px;
      width: 73px;
      height: 158px;
    }

    .left-image {
      position: absolute;
      left: 0;
      top: 220px;
    }
  }
}

@include adminAuthImageMobile("");

@media only screen and (min-device-width: 280px) and (max-device-width: 520px) {
  @include adminAuthImageMobile("mobile");

  .signin-signup-container-layout {
    width: 335px !important;
    //padding: 24px !important;
  }

  .signup-mobile-form {
    padding: 24px !important;
  }
}

@media only screen and (min-device-width: 520px) and (max-device-width: 560px) {
  @include adminAuthImageMobile("ipad");

  .signin-signup-container-layout {
    margin: 55px 3% 58px !important;

    .sign-in-grid {
      width: 450px !important;
    }
  }
}

@media only screen and (min-device-width: 560px) and (max-device-width: 600px) {
  @include adminAuthImageMobile("ipad");

  .signin-signup-container-layout {
    margin: 55px 6% 58px !important;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 960px) {
  .overlay-container {
    .MuiContainer-root {
      padding: 0;
    }
  }
}

@mixin underMaintenanceWrapper($type) {
  @if ($type ==mobile) {
    .sub-header {
      margin-bottom: 100px;
    }
  } @else if($type ==ipad) {
    .sub-header {
      margin-bottom: 200px;
    }
  } @else {
    .under-maintenance-header {
      margin: 35px 0px;
    }

    .under-maintenance-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .speed-logo {
      svg {
        width: 52px !important;
        height: 27px !important;
      }
    }

    .sub-header {
      margin-bottom: 150px;
    }

    .dot-style {
      margin: 0px 5px;
      font-size: $font20;
    }
  }

  .under-maintenance-grid {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 30px;
    margin-top: 158px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .footer-text-color {
    color: $darkIndigo;
  }

  .mt-25 {
    margin-top: 25px;
  }
}

.pay-via-options-payment {
  justify-content: space-around;
  @include commonPayVia();
  align-items: center;
  margin: 15px 0 !important;
}

.pay-via-options {
  @include commonPayVia();
  flex-flow: wrap;
  margin-bottom: 25px;
  width: 274px !important;
  justify-content: center;
  height: 45px;
}

.pay-option-logo {
  margin-right: 12px;
}

.pay-option-100 {
  // font-size: 11px !important;
  color: $greyText;
}

.pay-via-txt-payment {
  width: 470px;
  margin-top: 13px;
}

.pay-via-txt {
  width: 320px;
  margin-top: 13px;
}

@mixin common-page-box {
  @include commonCheckoutPageBoxCss();

  .checkout-link-footer {
    position: unset;
    bottom: unset;
    width: 468px;
    padding: 0 15px 15px;

    .checkout-page-text {
      color: $darkIndigo;
      font-size: $font14;

      .MuiSvgIcon-root {
        font-size: $font20;
        color: $darkIndigo;
        margin-left: 0;
        margin-top: -2px;
      }
    }

    .checkout-page-app-name {
      font-size: $font24;
      color: $darkIndigo;
      font-weight: 600;
    }
  }
}

.checkout-acc-currency {
  font-size: $font14 !important;

  &.animate-currency-text {
    animation: 1s anim-lineUp ease-out;
  }

  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }

    20% {
      opacity: 0;
    }

    50% {
      opacity: 1;
      transform: translateY(0%);
    }

    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

@mixin common-page-wrapper {
  @include commonMobileViewBrandingWithCheckoutWrapper();

  .checkout-payment-footer {
    display: flex;
    justify-content: space-between;
    margin: 0px 20px 20px;

    &.hide-timer-count {
      float: right;
    }

    .how-to-pay-box {
      display: flex;

      svg {
        height: 18px;
        width: 18px;
        position: relative;
        top: 5px;
        margin-right: 5px;
      }
    }
  }

  .text-mode-badge {
    height: 25px;
    width: auto;
    padding: 0px 10px;

    .MuiTypography-root {
      font-size: $font14 !important;
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;

    .crypto-address-text {
      cursor: pointer;
      border-radius: 21px;
      margin: 18px 36px 2px;
    }
  }

  .carousel-view {
    .MuiAccordionDetails-root {
      height: 410px;
    }
  }

  .web-preview-accordion {
    &.animation-height {
      height: 484px;
    }

    .hide-qr-code {
      display: none;
    }

    .animate-qr-code {
      animation: accordian-qr-code 1s linear;
    }

    @keyframes accordian-qr-code {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &.mobile-accordion {
      margin: 20px;
    }
  }
}

#checkout-help-drawer,
#checkout-view-drawer {
  .MuiBackdrop-root {
    background-color: unset;
  }
}

#hubspot-messages-iframe-container {
  z-index: 9999 !important;
}

.checkout-drawer {
  .MuiDrawer-paper {
    box-shadow: -10px 0 44px -2px rgba(11, 37, 75, 0.12),
      0 0 0 1px rgba(11, 37, 75, 0.08);
    overflow: hidden;
    border: none;

    .drawer-wrapper {
      width: 480px;
      height: 100%;
      min-width: 450px;

      .drawer-header {
        display: flex;
        padding: 20px 25px;
        background-color: $paleGray;
        justify-content: space-between;
        align-items: center;

        .drawer-help-icon {
          width: 24px;
          height: 24px;
          position: relative;
          top: 5px;
          margin-right: 13px;
          margin-left: 0;
        }
      }

      .view-calc-body-content {
        height: calc(100% - 70px);

        .paid-amount-detail-box {
          padding: 18px 30px 15px 25px;
          height: calc(100% - 120px);
          overflow: auto;

          .payment-paid-on-section {
            margin-bottom: 20px;

            .payment-id-box {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;

              .payment-id-text {
                max-width: 200px;
              }

              svg {
                font-size: $font16;
                top: 3px;
                position: relative;
                margin-left: 15px;
              }
            }
          }
        }

        .due-amount-detail-box {
          padding: 0px 30px 15px 25px;

          .amount-due {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .btc-due-amount {
              margin-bottom: 5px;
            }
          }
        }

        .total-payment {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }
      }

      .help-body-content {
        padding: 20px 28px 30px 28px;
        height: calc(100% - 110px);
        overflow: auto;

        .checkout-help-faq {
          border-radius: 5px;
          border: solid 1px $lightCyanBlue;
          background-color: $white;
          margin-bottom: 15px;

          .MuiAccordionSummary-root {
            padding: 16px 20px;
            color: $darkIndigo;
            min-height: auto;

            .MuiTypography-root {
              font-size: $font16;
            }

            svg {
              font-size: $font20;
              color: $greyText;
            }
          }

          .MuiAccordionDetails-root {
            height: auto;

            .MuiList-root {
              padding-top: 0px;

              .how-to-make-payment-txt {
                padding: 16px 38px 10px 20px;
              }
            }
          }

          &:last-child {
            .more-question-text {
              font-size: $font16;
              font-weight: 600;
              margin-bottom: 5px;
            }

            .more-question-subtext {
              font-size: $font14;
              line-height: 1.86;
            }
          }

          .faq-answer {
            padding: 16px 20px;
            padding-top: 15px;
            line-height: 1.86;

            .payment-method-title,
            .payment-method-steps {
              line-height: 30px;
            }

            .payment-method-title {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  @include scrollbar();
}

.confirm-payment-wrapper {
  @include addCustomScrollBar();

  .confirm-payment-preview {
    .grand-payment-box {
      margin-top: 25px;
    }
  }
}

.qr-modal {
  .MuiDialog-container {
    background-color: rgba(0, 0, 0, 0.27);
  }

  &.MuiDialog-root {
    .MuiDialog-paper {
      border-radius: unset;
    }

    .MuiDialogContent-root {
      padding: 15px;

      .qr-code {
        margin: 0;
      }
    }
  }
}

.preview-header-icon {
  height: 24px;
  width: 24px;
  color: $darkIndigo !important;
  margin-left: 0 !important;
}

#amount-options {
  &.MuiPopover-root {
    &.MuiMenu-root {
      position: absolute;
    }
  }
}

.amount-option-box {
  width: auto;
  height: 36px;
  border-radius: 2px;
  border: 1px solid $primaryBlue;
  margin-left: 15px;
  padding: 6px 12px;
  @include contentCenter();
  margin-top: 8px;
  cursor: pointer;
}

.amount-options-preview {
  &.MuiOutlinedInput-root {
    width: 440px;
    height: 60px;
    border-radius: 4px;
    margin: 6px 0 5px;

    .MuiSelect-outlined {
      padding: 6px 12px 6px;
      display: flex;
      align-items: center;

      .MuiTypography-root {
        font-weight: 600;
        color: $darkIndigo;
        max-width: 375px;
      }
    }

    &:focus-within {
      outline: none !important;
      border-radius: 2px !important;
    }

    .MuiSvgIcon-root {
      width: 24px !important;
      height: 30px !important;
      margin-top: -3px;
    }
  }
}

.modal-header-styles {
  filter: blur(10px);
  pointer-events: none;
  background: rgba(185, 185, 185, 0.6);
}

.header-modal {
  // height: 100%;
  min-height: fit-content;
  overflow: auto;
  max-height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  background: $white;
  box-shadow: 10px 14px 44px 0px rgba(0, 0, 0, 0.08);

  .mobile-modal-header {
    z-index: 1;
    background-color: white;
    position: sticky;
    top: 0;
  }
}

.tab-section-preview {
  width: 49%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  padding: 10px 16px;
}

.qr-preview-section {
  display: flex;
  padding: 16px 10px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid $lightCyanBlue;
  margin-top: 18px;

  .crypto-address-text {
    border-radius: 4px;
    border: 1px solid $lightCyanBlue !important;
    margin: 0;
    margin-top: 16px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .payment-page-qr-footer {
    margin: 0 !important;
    margin-top: 10px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}

@media only screen and(max-width:550px) {
  .qr-modal {
    &.MuiDialog-root {
      .MuiDialogContent-root {
        padding: 10px;

        .qr-code {
          svg {
            width: 250px !important;
            height: 250px !important;
          }
        }
      }
    }
  }
}

@mixin media-common-page-box {
  min-height: 100vh;

  .mobile-view-branding {
    @include commonMobileViewBranding();

    .mobile-view-box {
      min-height: 610px;
      height: auto;

      &.carousel-view {
        min-height: 628px;

        .MuiAccordionDetails-root {
          height: 400px;
        }

        .checkout-payment-footer {
          bottom: 15px;
        }
      }

      .mobile-view-header {
        height: auto;
        min-height: 155px;
      }
    }

    .account-icon-style {
      &.checkout {
        width: 22px !important;
        height: 22px !important;
      }
    }

    .account-logo-style {
      &.checkout {
        max-width: 125px;

        img {
          height: 22px !important;
          width: auto !important;
        }
      }
    }

    .checkout-acc-amount {
      font-size: $font28 !important;
    }

    .checkout-acc-currency {
      font-size: $font14 !important;
    }

    .web-preview-accordion {
      &.animation-height {
        height: 474px;
      }

      &.mobile-accordion {
        margin: 15px;
      }

      .speed-carousel {
        .slick-arrow {
          height: 24px;
          width: 24px;
        }
      }
    }

    .checkout-payment-footer {
      border-top: 1px solid $lightCyanBlue;
      bottom: 25px;
      margin: 0 15px;
      padding: 10px 15px 0px 15px;
      position: absolute;
      width: -webkit-fill-available;

      &.hide-timer-count {
        justify-content: right;
      }

      .how-to-pay-box {
        .MuiTypography-root {
          font-size: $font10;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  .checkout-link-footer {
    width: 100%;

    .checkout-page-text {
      font-size: $font10 !important;

      .MuiSvgIcon-root {
        font-size: $font12 !important;
      }

      .MuiTypography-root {
        font-size: $font10;
        line-height: 1.5;
      }
    }

    .checkout-page-app-name {
      font-size: $font20 !important;
    }
  }
}

.product-list-item {
  display: flex;
  margin-top: 24px;
  align-items: center;

  .product-image {
    height: 50px;
    width: 59px;
    color: #b8bfc7;
    margin-right: 15px;
    background-color: $whiteSmoke;
  }
}

.paid-payment-id {
  font-size: $font16;
}

.info-icon-payment-link {
  height: 18px;
  width: 18px;
  color: $greyText;
  margin-left: 5px;
  cursor: pointer;
}

.custom-field-preview {
  .custom-field-label {
    .MuiInputLabel-root {
      word-break: break-word;
      text-wrap: wrap;
      font-size: $font16;
      font-family: $fontMedium;
      color: $darkIndigo !important;
    }
  }

  .input-with-dropdown-label {
    font-size: $font16;
    font-family: $fontMedium;
    color: $darkIndigo !important;
    word-break: break-word;
    text-wrap: wrap;
  }
}

@include cfDDOptionsMenuTruncate(320px, false);

@mixin common-checkout-drawer {
  .checkout-drawer {
    .MuiDrawer-paper {
      width: 100%;

      .drawer-wrapper {
        width: 100% !important;
        min-width: auto;

        .drawer-header {
          padding: 16px 20px;

          .MuiTypography-root {
            font-size: $font14;
          }

          .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }
        }

        .view-calc-body-content {
          height: calc(100% - 55px);

          .paid-amount-detail-box {
            padding: 15px 20px;

            .payment-paid-on-section {
              .payment-id-box {
                .payment-id-text {
                  max-width: 127px;
                }
              }
            }
          }

          .due-amount-detail-box {
            .amount-due {
              .btc-due-amount {
                font-size: $font16;
              }
            }
          }
        }

        .help-body-content {
          padding: 20px;

          .checkout-help-faq {
            .MuiAccordionSummary-root {
              .MuiTypography-root {
                font-size: $font14;
              }
            }

            .MuiCollapse-root {
              .MuiAccordionDetails-root {
                .MuiTypography-root {
                  line-height: 1.5;
                }
              }
            }

            .faq-answer {
              .payment-method-title,
              .payment-method-steps {
                line-height: 25px !important;
              }
            }

            &:last-child {
              .more-question-subtext {
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}

@mixin commonInvoicePageAccordion {
  .invoice-page-accordion {
    width: auto;

    .MuiTableRow-root {
      .MuiTableCell-root {
        border-bottom: 0px;
        padding: 0px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .MuiTableRow-root:nth-of-type(even) {
      background-color: transparent;
    }

    .invoice-info-tbl-row {
      display: block;
      padding: 4px 0px;
      border-bottom: 0;
      background-color: $white;
    }
  }
}

.invoice-info-accordion {
  margin: 10px 15px !important;
}

@mixin commonItemsSection {
  text-align: center;
  margin: auto;
  width: 90px;
  background-color: $white;
  height: 32px;
  margin-bottom: 20px;
  margin-top: 30px;
  border-radius: 4px;
  @include contentCenter();
}

@mixin common-checkout-loader {
  .checkout-loader-content {
    height: 386px;
    margin: 15px;

    .MuiSkeleton-root {
      &.MuiSkeleton-rectangular {
        height: 163px !important;
        width: 163px !important;
        margin-top: 55px;
      }
    }
  }
}

@include underMaintenanceWrapper("");

@media only screen and (min-width: 280px) and (max-width: 940px) {
  @include underMaintenanceWrapper("mobile");

  .vector-diagonal-div {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  }

  .vector-diagonal-sub-div {
    clip-path: polygon(0 34%, 100% 0, 100% 8%, 0 45%);
    margin-top: 680px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  @include underMaintenanceWrapper("ipad");
}

.checkout-web-preview {
  display: none !important;
}

.checkout-mobile-preview {
  display: none !important;
}

@mixin tableRowCommonCss() {
  .MuiTableRow-root {
    .MuiTableCell-root {
      border-bottom: 0px;
      padding: 0px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .MuiTableRow-root:nth-of-type(even) {
    background-color: transparent;
  }
}

@mixin invoiceInfoOnPaymentPageSectionCommonCss() {
  .email-info-section {
    margin: 37px 0px;
    padding: 30px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    @include tableRowCommonCss();

    .invoice-info-tbl-row {
      background-color: transparent;
      display: block;
      padding: 4px 0px;
    }

    .product-section {
      margin: 37px 0px;
      padding: 30px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
}

@media (max-width: 1023px) {
  .checkout-mobile-preview {
    display: flex !important;
  }
}

@media (min-width: 1024px) {
  .checkout-web-preview {
    display: block !important;
  }
}

@media only screen and (min-device-width: 376px) and (max-device-width: 767px) {
  .header-modal {
    // height: 100vh;
    // min-height: fit-content;
    // overflow: auto;
    // max-height: 100%;
  }
}

@media only screen and (min-width: 540px) {
  .header-modal {
    // height: 100%;
    // min-height: fit-content;
    // overflow: auto;
    // max-height: 100%;
  }
}

@media only screen and (max-width: 280px) {
  @include cfDDOptionsMenuTruncate(210px);
}

@media (min-width: 280px) and (max-width: 600px) {
  .checkout-page-box {
    .checkout-page-wrapper {
      @include contentCenter();
      height: 100vh;
    }

    .mobile-view-box {
      min-height: 610px;
      height: auto;

      &.carousel-view {
        min-height: 628px;

        .MuiAccordionDetails-root {
          height: 400px;
        }

        .checkout-payment-footer {
          bottom: 15px;
        }
      }

      .mobile-view-header {
        height: auto;
        min-height: 155px;
      }
    }

    @include media-common-page-box();
  }

  @include common-checkout-drawer();
  @include common-checkout-loader();
}

@mixin contactInfoWrapper {
  .payment-page-contact-info {
    .input-element {
      .MuiInputBase-root {
        border-top: 1px solid $lightCyanBlue;
        border-right: 1px solid $lightCyanBlue;
        border-left: 1px solid $lightCyanBlue;
        border-bottom: unset;

        .MuiInputBase-input {
          box-shadow: none;
        }

        &.Mui-focused {
          z-index: 2;
        }
      }

      .custom-input-city-info {
        border-bottom: 1px solid $lightCyanBlue;
      }

      .custom-input-zip-info {
        border-bottom: 1px solid $lightCyanBlue;
      }
    }

    .auto-complete {
      .MuiAutocomplete-root {
        .MuiFormControl-root {
          .MuiInputBase-root {
            border-top: 1px solid $lightCyanBlue !important;
            border-right: 1px solid $lightCyanBlue !important;
            border-left: 1px solid $lightCyanBlue !important;
            border-bottom: unset !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .contact-info-phone-number-input {
      .dropdown-input-wrapper {
        box-shadow: none;
        border: 1px solid $lightCyanBlue;
        border-radius: 0px 0px 4px 4px !important;

        .phone-number-input {
          border-radius: 0px 0px 4px 0px !important;
        }

        .phone-number-input-error {
          border: 1px solid $paleRed;
        }

        .css-so1p7h-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-roo {
          border-radius: unset !important;
        }
      }
    }

    .custom-auto-complete {
      .MuiAutocomplete-input {
        padding-right: 6px;
      }

      .MuiOutlinedInput-root {
        border-radius: unset !important;
        padding: 0px 3px !important;
        height: 37px !important;

        &.Mui-focused {
          outline: 3px solid $outlineBlue;
          z-index: 2;
        }
      }
    }
  }
}

.checkout-actions-class {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center !important;
}

@media only screen and (max-width: 331px) {
  .under-maintenance-image {
    width: 200px;
  }

  .checkout-link-footer {
    width: 215px;
  }

  .acc-name-loader {
    width: 125px !important;
  }

  .timer-loader {
    width: 40px !important;
  }

  .custom-popper {
    .MuiBox-root {
      max-width: 245px !important;
    }
  }

  .checkout-acc-amount {
    max-width: 245px;
  }

  .expire-modal {
    .MuiDialog-paper {
      width: 200px !important;

      .MuiDialogContent-root {
        padding: 15px 20px;
        flex-direction: column;
        @include contentCenter();

        .expire-icon {
          margin: 15px 0 !important;
        }

        .expire-header {
          max-width: 160px;
          font-size: $font16;
        }

        .expire-sub-header {
          max-width: 160px;
          font-size: $font12;
        }

        .expire-input-box {
          margin-top: 15px !important;

          .MuiInputBase-root {
            input {
              font-size: $font14;
            }
          }
        }
      }

      .MuiDialogActions-root {
        padding: 0px 20px 20px !important;

        .MuiButtonBase-root {
          font-size: $font14;
        }
      }
    }
  }

  .contact-address,
  .contact-name,
  .contact-line1,
  .contact-state-code,
  .contact-country {
    max-width: 245px !important;
    word-break: break-word;
  }

  .logo-class {
    width: 46px;
  }

  .amount-options-preview {
    &.MuiOutlinedInput-root {
      .MuiSelect-outlined {
        .MuiTypography-root {
          max-width: 195px !important;
        }
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) {
  .checkout-acc-amount {
    max-width: 335px;
  }

  .contact-address,
  .contact-name,
  .contact-line1,
  .contact-state-code,
  .contact-country {
    max-width: 350px;
    word-break: break-word;
  }
}

// Link not found

.link-not-found-wrapper {
  text-align: center;
  flex-direction: column;
  height: 100%;
  @include contentCenter();
}

.center-icon {
  @include contentCenter();
}

.warning-container {
  width: 126px;
  height: 126px;
  background-color: $paleGray;
  border-radius: 50%;
}

.not-found-wrapper {
  height: 100%;
  padding: 18px 30px 100px 40px;

  .warning-icon {
    width: 63px;
    height: 63px;
    margin: 25px 0 0 0;
    color: $greyText;
  }
}

.not-found-link-title {
  margin-top: 27px;
  color: $darkIndigo;
}

.not-found-link-sub-title {
  margin-top: 20px;
  color: $greyText;
}

.view-live-mode-link-btn {
  margin-top: 30px;
}

// date-picker
.custom-date-picker-textfield {
  & .MuiOutlinedInput-root {
    margin: 0 4px;

    &.Mui-error {
      border: 0px solid transparent !important;

      & .MuiOutlinedInput-notchedOutline {
        @include applyBorder($paleRed);
      }
    }

    &.Mui-focused {
      @include applyFocusBorder();

      & .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
        box-shadow: none;
      }
    }

    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid transparent !important;
      }
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent;
    @include boxShadow();
  }
}

.custom-date-picker {
  box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
  border-radius: 1;
  border: solid 1px $lightCyanBlue;

  & .MuiCalendarPicker-root {
    font-family: $fontRegular;

    & .MuiPickersDay-today {
      border: 1px solid $sunFlowerYellow !important;
      background-color: transparent;
    }

    & .PrivatePickersFadeTransitionGroup-root {
      & [aria-live="polite"] {
        font-family: $fontMedium;
      }
    }

    & .PrivatePickersYear-yearButton {
      font-size: $font16;
    }

    & .MuiPickersDay-root {
      font-size: $font14;
    }

    & .MuiPickersDay-root,
    & .PrivatePickersYear-yearButton {
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        background-color: $paleGray;
      }

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  & .Mui-selected {
    background-color: $sunFlowerYellow !important;
    color: $darkIndigo !important;
  }
}

.dropdown-input-wrapper {
  &.instant-payout-select-input {
    .MuiOutlinedInput-input {
      padding: 13px 30px 10px 14px !important;
    }
  }

  &.disable-amount-input {
    background-color: $lightAliceBlue;

    .MuiOutlinedInput-root {
      background-color: $lightAliceBlue !important;
    }
  }
}

.footer-wrapper {
  background-color: white;
  bottom: 0;
  position: fixed;
  width: calc(100% - 240px);

  .form-footer {
    padding: 20px 40px;
  }
}

.wallets-custom-autocomplete {
  .MuiIconButton-root {
    position: unset;
    color: $greyText;
  }

  .MuiFormLabel-root {
    white-space: unset;
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      .MuiAutocomplete-listbox {
        padding: 0 !important;
      }
    }
  }
}

.reason-text-area {
  box-sizing: border-box;
}

.report-issue-text {
  line-height: 140%;
  width: 83px;
  height: 34px;
  @include applyBorder($lightCyanBlue);
  border-radius: 4px;
}

.submit-feedback-box {
  padding: 11px 16px;
  border-radius: 4px;
  cursor: pointer;
  @include applyBorder($lightCyanBlue);
  @include contentCenter();
}

.end-user-feedback-success {
  .MuiDialog-paper {
    width: 340px;

    .MuiDialogContent-root {
      padding-top: 54px;
      padding-bottom: 40px;
    }
  }
}

.header-clipboard {
  .MuiInputBase-root {
    width: 300px;
  }
}

.header-detail-wrapper {
  .name-box {
    display: flex;
    align-items: center;
  }

  .copy-clipboard-box {
    margin-top: 15px;
  }
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24 !important;
}

.material-symbols-outlined:nth-of-type(1) {
  font-size: x-large !important;
}

@mixin largeIcon() {
  .material-symbols-outlined:nth-of-type(1) {
    font-size: large !important;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.txt-uppercase {
  text-transform: uppercase;
}
